<template>
  <div>
    <el-dialog
      title="订单详情"
      :visible.sync="show"
      class="lookordermain"
      width="400px"
      @close="exit()"
    >
      <div v-if="nomsg">没有数据</div>
      <div v-else>
        <p class="topp">
          <img :src="moneyicon" alt="" />
          <span>{{ money }}</span>
        </p>
        <p style="margin-bottom:24px;">
          <img :src="ordericon" alt="" class="ordericon" />
          <span style="color:#303133;">当前状态</span>
          <span style="color:#303133;" class="right">{{ status }}</span>
        </p>
        <p v-if="jydhshow">
          <span>交易单号</span>
          <span class="right">{{ jydh }}</span>
        </p>
        <el-divider></el-divider>
        <p>
          <span>支付时间</span>
          <span class="right">{{ time }}</span>
        </p>
        <el-divider></el-divider>
        <p>
          <span>商户单号</span>
          <span class="right">{{ yf_order_no }}</span>
        </p>
        <el-divider></el-divider>
        <p>
          <span>客服编号</span>
          <span class="right">{{ input_id }}</span>
        </p>
        <p class="heji">
          <span>合计：</span>
          <span>{{ hejimoney }}</span>
        </p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["lookorder", "talkorderid", "talkordertype"],
  data() {
    return {
      show: false,
      status: "",
      moneyicon: require("../assets/ordermoney.svg"),
      ordericon: require("../assets/order.svg"),
      time: "",
      money: "",
      hejimoney: "",
      input_id: "",
      yf_order_no: "",
      jydh: "",
      jydhshow: true,
      nomsg: false,
    };
  },
  watch: {
    lookorder(o, n) {
      this.show = o;
      this.getdetail();
    },
  },
  mounted() {},
  methods: {
    exit() {
      this.show = false;
      this.$emit("update:lookorder", false);
    },
    getdetail() {
      var url = "",
        list = {};
      if (this.talkordertype == "zf") {
        url = "/yf/get_gu_pat_servorder_by_id?id=";
      } else {
        // url = "/docpres/get_gu_docpres_order_by_id";
        url="/order/get_order_by_order_id?order_id="
      }
      this.axios.get(url + this.talkorderid).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.nomsg = false;
          list = res.data.result;
          if (this.talkordertype == "zf") {
            this.money = list.trans_amount_str;
            this.hejimoney = list.trans_amount_str;
            this.input_id = list.input_id;
            this.yf_order_no = list.yf_order_no;
            this.jydh = list.yf_id;
            if (list.pay_time != "" && list.pay_time != null && list.pay_time != "null") {
              this.time = list.pay_time.substr(0, list.pay_time.length - 4);
            }
            this.status = this.dingdanzhuangtai(res.data.result.state);
          } else {
            this.money = list.Amount;
            this.hejimoney = list.Amount;
            this.input_id = list.Cust_Servicer;
            this.yf_order_no = list.Order_ID;
            this.jydhshow = false;
            if (list.Pay_Date != "" && list.Pay_Date != null && list.Pay_Date != "null") {
              this.time = list.Pay_Date.substr(0, list.Pay_Date.length - 4);
            }
            this.status = this.dingdanzhuangtai(res.data.result.Order_state);
          }
          
        } else {
          this.money = "";
          this.hejimoney = "";
          this.input_id = "";
          this.yf_order_no = "";
          this.jydh = "";
          this.time = "";
          this.status = "";
          this.nomsg = true;
        }
      });
    },
    //订单状态
    dingdanzhuangtai(val){
      var str = "";
      var dic = {
        '-1':"付款失败",
        0:"未付款",
        1:"已付款",
        2:"已确认",
        6:"已退款",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    }
  },
};
</script>
<style>
.lookordermain {
  text-align: left;
}
.topp {
  text-align: center;
  line-height: 58px;
  font-size: 28px;
  margin-bottom: 60px;
}
.topp img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
.right {
  float: right;
}
.ordericon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}
.heji {
  margin-top: 60px;
  color: #409eff;
  font-size: 18px;
}
</style>
